import React from "react"

import Layout from "../components/Layout"
import { Link } from "gatsby"
import { PORTAL_URL } from "../utils/Constants"

const UnSubscribedPage = () => {
  return (
    <Layout>
      <div
        className="flex items-center justify-center"
        style={{ height: "40vh" }}
      >
        <div className="text-center">
          <h1 style={{ fontSize: "1.4em", fontWeight: "bold" }}>
            Emails Unsubscribed
          </h1>
          <p>
            You can{" "}
            <Link to={PORTAL_URL}>
              <span style={{ color: "blue", textDecoration: "underline" }}>
                sign in
              </span>
            </Link>{" "}
            and subscribe emails again from your profile.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default UnSubscribedPage
